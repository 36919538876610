<template>
	<div class="ypxx">
		<tabBar></tabBar>
		<div class="zhenshu">
			<img src="../assets/img/xie/zhenshu.jpg" />
		</div>
		<footers></footers>
	</div>
</template>

<script>
	import tabBar from "@/components/tabBar.vue";
	import footers from "@/components/footer.vue";
	export default{
		components:{
			tabBar,
			footers
		}
	}
</script>

<style lang="scss" scoped>
	.zhenshu{
		margin-top: 80px;
		text-align: center;
		img{
			width: 900px;
		}
	}
</style>